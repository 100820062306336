<template>
  <section class="form">
    <v-form class="the_form_wrapper" @submit.prevent="submit" v-model="valid">
      <v-row>
        <v-col class="kpb-0" cols="12"  md="4">
          <v-text-field
              placeholder="الاسم بالكامل"
              v-model="form_data.first_name"
              filled
              rounded
              dense
              background-color="#fff"
              reverse
              :rules="nameRules"
          ></v-text-field>
          <!-- email -->
          <v-text-field
              v-model="form_data.email"
              placeholder="البريد الالكتروني"
              filled
              rounded
              dense
              background-color="#fff"
              reverse
              :rules="emailRules"
          ></v-text-field>
        </v-col>
        <v-col class="kpb-0" cols="12"  md="4">
          <div class="phone_wrapper">
            <v-text-field
                v-model="form_data.phone"
                placeholder="رقم الهاتف"
                filled
                rounded
                dense
                background-color="#fff"
                reverse
                :rules="phoneRules"
            >
              <template  v-if="loading" v-slot:append>
                <v-progress-circular
                    class="n_t"
                    size="24"
                    color="info"
                    indeterminate
                ></v-progress-circular>
              </template>
              <template v-else v-slot:append>
                <vue-country-code
                    :defaultCountry="form_data.country"
                    ref="vcc"
                    disabledFetchingCountry
                    @onSelect="onSelect">
                </vue-country-code>
              </template>
            </v-text-field>
          </div>
          <v-text-field
              :value="country_name.name"
              placeholder="الدولة"
              filled
              rounded
              dense
              background-color="#fff"
              reverse
              disabled
          ></v-text-field>
        </v-col>
        <v-col class="kpb-0" sm="12" md="4">
          <div class="the_button">
            <button class="form">
          <span v-if="!loading_submit">
            سجل الان
          </span>
              <v-progress-circular
                  v-else
                  size="24"
                  color="info"
                  indeterminate
              ></v-progress-circular>
            </button>
            <p class="red" v-if="error">{{ error }}</p>
          </div>
          <div @click="$router.push({name: 'Terms'})" style="cursor: pointer">
            <v-checkbox
                class="check_box_wrapper"
                v-model="ex4"
                label="أوافق على سياسة الخصوصية, والأحكام والشروط* وعلى أن أتلقى مواداً تسويقية."
                hide-details
                color="success"
                disabled
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import VueCountryCode from "vue-country-code";
import {get_user_country, insert_data} from "@/api/general";
import countryList from "@/assets/js/countrylist.json";

export default {
  components:{VueCountryCode,},
  data: () => ({
    country_name: '',
    ex4: true,
    pressedRegister: false,
    loading_submit: false,
    loading: false,
    valid: false,
    error: '',
    form_data: {
      first_name: null,
      last_name: null,
      email: null,
      phone: '',
      country: null,
      source: null,
      page_name: null,
    },
    nameRules: [
      v => !!v || 'برجاء ادخال الاسم',
    ],
    emailRules: [
      v => !!v || 'برجاء ادخال البريد الالكتروني',
      v => /.+@.+/.test(v) || 'برجاء ادخال البريد الالكتروني',
    ],
    phoneRules: [
      v => !!v || 'برجاء ادخال رقم الهاتف',
      v => v.length >= 7 || 'يجب ان يكون الهاتف اكبر من 7 ارقام',
    ]
  }),
  methods: {
    onSelect({iso2}) {
     this.form_data.country = iso2
     this.country_name = countryList.find(el => el.code === iso2);
    },
    showForm() {
      this.loading = true;
      get_user_country()
      .then(response => {
        this.form_data.country = response.data;
        this.form_data.source = window.location.search.substring(1);
        this.form_data.page_name = window.location.pathname.substring(1);
        this.loading = false;
        this.pressedRegister = true;

      })
    },
    submit() {
      if(this.valid) {
        this.loading_submit = true;
        insert_data(this.form_data).then(response => {
          this.loading_submit = false;
          console.log(response);
          window.location.href = '/well-done';
          // this.$router.push({name: 'Done'});
        }).catch((err) => { this.error = err.response.data.msg; this.loading_submit = false; })
      }
    }
  },
  mounted() {},
  created() {
    this.showForm();
  }
}
</script>

<style scoped lang="scss">
.phone_wrapper {
  display: flex;
  align-items: center;
  .vue-country-select, .n_t {
    background: #ffffff;
    border-radius: 0;
    border: 0;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
  }
}

.check_box_wrapper::v-deep {
  .v-input__slot {
    display: flex;
    align-items: flex-start;
  }
  i {
    color: #00cb39 !important;
  }
  label {
    font-size: 10px !important;
    text-align: right;
    color: #fff;
    padding-inline: 13px;
    cursor: pointer !important;
  }
}
</style>